import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login')
  },
  {
    path: '/limit',
    name: 'limit',
    component: () => import('@/views/error/limit')
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/views/user/changePassword')
  },
  {
    path: '/ticketNum',
    name: 'ticketNum',
    component: () => import('@/views/verify/ticketNum')
  },
  {
    path: '/confirmVerify',
    name: 'confirmVerify',
    component: () => import('@/views/verify/confirmVerify')
  },
  {
    path: '/verifyResult',
    name: 'verifyResult',
    component: () => import('@/views/verify/verifyResult')
  },
  {
    path: '/verifyRecord',
    name: 'verifyRecord',
    component: () => import('@/views/history/verifyRecord')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/history/detail')
  }
]


const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
