import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "lib-flexible/flexible"
import FastClick from "fastclick"
import { Button, ShareSheet, Toast, Notify } from "vant";
import Navigation from 'vue-navigation'
import Vconsole from 'vconsole'
import { getToken } from "@/utils/auth";
import onReachBottom from './components/onReachBottom.vue';
Vue.component('v-reload', onReachBottom);
// const vConsole = new Vconsole()
// Vue.use(vConsole)
let whitePath = ['/limit'];
let loginWhitePath = ['/login', '/limit'];
Vue.use(Navigation, { router })
Vue.use(Button).use(ShareSheet).use(Toast)

Vue.config.productionTip = false

FastClick.attach(document.body);//解决移动端点击

// 判断登录
let judgeLogin = (to, from, next) => {
  if (getToken()) {
    next();
  } else {
    if (loginWhitePath.indexOf(to.path) != -1) {
      next()
    } else {
      // Notify({ type: "danger", message: "登录已过期，请重新登录！" });
      next('/login')
    }
  }
}

router.beforeEach((to, from, next) => {
  // if (from.path == '/' && from.name == null && whitePath.indexOf(to.path) == -1) { //保证只有刚进入才调用
  //   let ua = window.navigator.userAgent.toLowerCase();
  //   if (ua.match(/MicroMessenger/i) == 'micromessenger'&&ua.match(/wxwork/i) != 'wxwork') {  //微信环境
  //     judgeLogin(to, from, next);
  //   } else {
  //     next('/limit')
  //   }
  // } else {
  //   judgeLogin(to, from, next);
  // }
  judgeLogin(to, from, next);
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
