import request from '@/utils/request'

// 用户名密码登录
export function loginByUsername(data) {
	return request({
		url: '/verifier/loginByPhone',
		method: 'post',
		data
	})
}

// 用户退出
export function logout(data) {
	return request({
		url: '/verifier/logout',
		method: 'post',
		data
	})
}

// 获取当前登录用户信息
export function queryUserInfo() {
	return request({
		url: '/verifier/info',
		method: 'get'
	})
}

// 刷新token
export function refreshTokenApi(data) {
	return request({
		url: '/verifier/refreshToken',
		method: 'get',
		params: data
	})
}

// 修改密码
export function changePassword(data) {
	return request({
		url: '/verifier/change/password',
		method: 'post',
		data
	})
}

// 查询券详情
export function ticketDetial(data) {
	return request({
		url: '/verifier/verify/couponInfo',
		method: 'get',
		params: data
	})
}


//核销券码 
export function verifyTicket(data) {
	return request({
		url: '/verifier/verify/useCouponNum',
		method: 'post',
		data
	})
}

// 获取核销记录
export function verifyRecord(data) {
	return request({
		url: '/verifier/snapshot/page',
		method: 'post',
		data
	})
}

// 查询历史券详情
export function ticketHistoryDetial(data) {
	return request({
		url: '/verifier/snapshot/info/' + data,
		method: 'get',
	})
}

// 撤销接口
export function rebackTicket(data) {
	return request({
		url: '/verifier/snapshot/verifierUseCouponNum',
		method: 'get',
		params: data
	})
}
// 获取签名
export function getSignature(data) {
	return request({
		url: '/wx/getSignature',
		method: 'get',
		params: data
	})
}




