<template>
  <div id="app">
    <navigation>
      <router-view class="router-view"> </router-view>
    </navigation>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: 'PingFang SC'; */
}
body,
dl,
dd,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
input,
textarea,
p,
hr,
thead,
tbody,
tfoot,
th,
td {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
}
html {
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  height: 100%;
  width: 100%;
}
body {
  line-height: 1.5;
  font-size: 14px;
  height: 100%;
  width: 100%;
}
body,
button,
input,
select,
textarea {
  font-family: "helvetica neue", tahoma, "hiragino sans gb", stheiti,
    "wenquanyi micro hei", \5fae\8f6f\96c5\9ed1, \5b8b\4f53, sans-serif;
}
b,
strong {
  font-weight: bold;
}
i,
em {
  font-style: normal;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
table th,
table td {
  border: 1px solid #ddd;
  padding: 5px;
}
table th {
  font-weight: inherit;
  border-bottom-width: 2px;
  border-bottom-color: #ccc;
}
img {
  border: 0 none;
  width: auto\9;
  max-width: 100%;
  vertical-align: top;
  height: auto;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input:focus {
  outline: none;
}
select[size],
select[multiple],
select[size][multiple] {
  border: 1px solid #aaa;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video,
progress {
  display: inline-block;
}
body {
  background: #fff;
}
input::-webkit-input-speech-button {
  display: none;
}
button,
input,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar {
  display: none;
}
#app {
  height: 100%;
  width: 100%;
  font-family: Microsoft YaHei;
}
</style>
