import axios from 'axios'
import { Notify } from 'vant'
import store from '@/store'
import { getToken, removeToken, getRefreshToken } from '@/utils/auth'
import router from '@/router'

const defaultSettings = require('../../src/settings.js')
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000 //  请求超时
})

async function doRequest(error) {
    const data = await store.dispatch('user/getRefreshToekn')
    let { access_token: accessToken } = data.data
    let token = defaultSettings.tokenHead + accessToken
    let config = error.config
    config.headers.Authorization = token
    const res = await axios.request(config)
    return res.data
}

// request拦截器
service.interceptors.request.use(
    config => {
        if (store.state.user.token) {
            // 请求时携带token
            config.headers[defaultSettings.authorization] = getToken()
        }
        return config
    },
    error => {
        // 处理请求错误
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response拦截器
service.interceptors.response.use(
    response => {
        // 判断refreshToken接口
        let isRefresh = response.config.url.endsWith('/verifier/refreshToken');
        const res = response.data
        if (res.code === 401 && !isRefresh) {
            if (router.currentRoute.path !== '/login') {
                if (getRefreshToken()) {
                    return doRequest(response)
                } else {
                    removeToken()
                    router.push({ path: '/login' })
                    Notify({ type: 'danger', message: '登录验证失败，请重新登录！' });
                }
            } else {
                Notify({ type: 'danger', message: '登录验证失败，请重新登录！' });
            }
        } else if (res.code === 401 && isRefresh) {
            removeToken()
            router.push({ path: '/login' })
            Notify({ type: 'danger', message: '登录验证失败，请重新登录！' });
        } else if (res.code !== 0) {
            Notify({ type: 'danger', message: res.msg });
            return Promise.reject('error')
        } else {
            return res
        }
    },
    error => {
        Notify({ type: 'danger', message: error });
        return Promise.reject(error)
    })

export default service
